exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customs-clearance-tsx": () => import("./../../../src/pages/customs-clearance.tsx" /* webpackChunkName: "component---src-pages-customs-clearance-tsx" */),
  "component---src-pages-data-protection-tsx": () => import("./../../../src/pages/data-protection.tsx" /* webpackChunkName: "component---src-pages-data-protection-tsx" */),
  "component---src-pages-for-business-tsx": () => import("./../../../src/pages/for-business.tsx" /* webpackChunkName: "component---src-pages-for-business-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-1-tsx": () => import("./../../../src/pages/news/1.tsx" /* webpackChunkName: "component---src-pages-news-1-tsx" */),
  "component---src-pages-news-2-tsx": () => import("./../../../src/pages/news/2.tsx" /* webpackChunkName: "component---src-pages-news-2-tsx" */),
  "component---src-pages-news-3-tsx": () => import("./../../../src/pages/news/3.tsx" /* webpackChunkName: "component---src-pages-news-3-tsx" */),
  "component---src-pages-news-4-tsx": () => import("./../../../src/pages/news/4.tsx" /* webpackChunkName: "component---src-pages-news-4-tsx" */),
  "component---src-pages-news-5-tsx": () => import("./../../../src/pages/news/5.tsx" /* webpackChunkName: "component---src-pages-news-5-tsx" */),
  "component---src-pages-news-6-tsx": () => import("./../../../src/pages/news/6.tsx" /* webpackChunkName: "component---src-pages-news-6-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-send-tsx": () => import("./../../../src/pages/send.tsx" /* webpackChunkName: "component---src-pages-send-tsx" */),
  "component---src-pages-service-points-tsx": () => import("./../../../src/pages/service-points.tsx" /* webpackChunkName: "component---src-pages-service-points-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-track-and-receive-tsx": () => import("./../../../src/pages/track-and-receive.tsx" /* webpackChunkName: "component---src-pages-track-and-receive-tsx" */),
  "component---src-pages-track-tsx": () => import("./../../../src/pages/track.tsx" /* webpackChunkName: "component---src-pages-track-tsx" */)
}

